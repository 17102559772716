// External
import React, { useContext, useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import cn from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Internal
import LocalImage from '../../../../components/LocalImage';
import { useWindowWidth } from '../../../../utils/hooks';
import { StoreContext } from '../../../../context/store-context';

const ProductPhotoSlideContainer = ({
	product,
	thumbsSwiper,
	customPreviewImage,
	setSliderNoAnimation,
}) => {
	const { variantSelected, setSlideProperties, setModal, showModal } =
		useContext(StoreContext);
	const windowWidth = useWindowWidth();

	const [swiper, setSwiper] = useState(null);

	const imagesUrls =
		(product?.media || []).length &&
		product?.media.map((item, index) => ({
			url: item.shopifyId,
			originalSrc: item.preview.image.originalSrc,
			index,
		}));

	const handleClick = (index) => {
		if (windowWidth < 750) {
			setModal(null, 'zoom-images-mobile', null, {
				product,
				customPreviewImage,
				index,
			});
			showModal(true);
		}
	};

	useEffect(() => {
		let currentSlide = null;

		if (
			imagesUrls &&
			imagesUrls.length &&
			swiper &&
			variantSelected &&
			variantSelected?.image
		) {
			const variantTitle = variantSelected.title;
			const transformedVariantTitle =
				typeof variantTitle === 'string' &&
				variantTitle.toLowerCase().replaceAll(' ', '-');

			currentSlide = imagesUrls.filter((item) =>
				item.originalSrc.includes(transformedVariantTitle)
			)[0];

			!currentSlide
				? (currentSlide = imagesUrls.filter(
						(item) =>
							item.originalSrc &&
							item.originalSrc.includes(
								variantSelected.image.originalSrc
							)
				  )[0])
				: currentSlide;

			setSliderNoAnimation(true);

			currentSlide &&
				Number.isInteger(currentSlide?.index) &&
				swiper.slideTo(currentSlide.index);
			setTimeout(() => {
				setSliderNoAnimation(false);
			}, 100);
		}
	}, [variantSelected, setSlideProperties, swiper]);

	return (
		<>
			{Array.isArray(product?.media) && product.media.length ? (
				<Swiper
					onSwiper={setSwiper}
					slidesPerView={windowWidth > 749 ? 1 : 2}
					simulateTouch='false'
					centeredSlides='false'
					spaceBetween={10}
					className='gallery-top'
					thumbs={{ swiper: thumbsSwiper }}
					breakpoints={{
						750: { spaceBetween: 0 },
					}}
					preloadImages={false}
					lazy
				>
					{customPreviewImage && (
						<SwiperSlide
							className={cn('pdp-photo-slider__item', {
								'pdp-photo-slider__item--center':
									(product?.media || []).length === 1,
							})}
							key={customPreviewImage}
						>
							<div
								id='pdpPhotoSliderImg'
								className='pdp-photo-slider__img'
							>
								{customPreviewImage.gatsbyImageData ? (
									<GatsbyImage
										className='swiper-lazy'
										alt={product.title}
										image={getImage(
											customPreviewImage.gatsbyImageData
										)}
									/>
								) : (
									<LazyLoadImage
										className='swiper-lazy'
										src={customPreviewImage.originalSrc}
										alt={product.title}
									/>
								)}
							</div>
						</SwiperSlide>
					)}
					{Array.isArray(product?.media) &&
						product.media.map((image, index) => (
							<SwiperSlide
								className={cn('pdp-photo-slider__item', {
									'pdp-photo-slider__item--center':
										(product?.media || []).length === 1,
								})}
								key={image.id}
								onClick={
									windowWidth < 750
										? () => handleClick(index)
										: null
								}
							>
								<div
									id='pdpPhotoSliderImg'
									className='pdp-photo-slider__img'
								>
									{image?.preview?.image?.localFile
										?.childImageSharp?.gatsbyImageData &&
									!image?.preview.image.originalSrc?.includes(
										'.gif'
									) ? (
										<GatsbyImage
											className='swiper-lazy'
											loading={
												index > 0 ? 'lazy' : 'eager'
											}
											alt={image.shopifyId}
											image={
												image.preview.image.localFile
													.childImageSharp
													.gatsbyImageData
											}
										/>
									) : (
										<LazyLoadImage
											className='swiper-lazy'
											src={
												image.preview.image.originalSrc
											}
											alt={image.shopifyId}
										/>
									)}
								</div>
							</SwiperSlide>
						))}
				</Swiper>
			) : (
				<LocalImage image='no-image.jpg' alt={product?.title} />
			)}

			{windowWidth < 750 && (
				<div className='pdp-button-zoom' onClick={() => handleClick(0)}>
					<LazyLoadImage
						src='/images/lupe-zoom-in.svg'
						alt='Arrow up'
						effect='blur'
					/>
				</div>
			)}
		</>
	);
};

export default ProductPhotoSlideContainer;
