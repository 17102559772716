export default [
	{
		imgSrc: 'https://cdn.shopify.com/s/files/1/0266/7223/4576/files/Untitled_design_52.png',
		title: 'Hair care products',
		handle: '/collections/care',
	},
	{
		imgSrc: 'https://cdn.shopify.com/s/files/1/0266/7223/4576/collections/permanent-colour-60-light-brown-897738.jpg',
		title: 'Hair colour',
		handle: '/collections/colour',
	},
	{
		imgSrc: 'https://cdn.shopify.com/s/files/1/0266/7223/4576/collections/smoky-brunette-hair-gloss-803035.jpg',
		title: 'The Gloss and Glaze Collection',
		handle: '/collections/gloss-and-glaze',
	},
	{
		imgSrc: 'https://cdn.shopify.com/s/files/1/0266/7223/4576/collections/1_776015a6-79c9-4982-aa5a-38523da68bf9.jpg',
		title: 'Root touch up',
		handle: '/collections/root-touch-up',
	},
];
