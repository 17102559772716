import { Link } from 'gatsby';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getMetafield } from '../../utils/helpers';

const AnotherListItem = ({ product }) => {
	const url = getMetafield(product, 'url');

	return (
		<li className=' product-list-item  product-list-item--hover'>
			<Link to={url}>
				<div className='product-list-item__img'>
					{product.media[0] &&
					product.media[0].preview.image.localFile.childImageSharp
						.gatsbyImageData ? (
						<GatsbyImage
							image={getImage(
								product.media[0].preview.image.localFile
									.childImageSharp.gatsbyImageData
							)}
							alt={product && product.title}
						/>
					) : (
						<LazyLoadImage
							src={product.media[0].preview.image.originalSrc}
							alt={product.title}
							effect='blur'
						/>
					)}
				</div>
			</Link>
			<div
				className='product-list-item__text'
				dangerouslySetInnerHTML={{ __html: product?.title }}
			/>
		</li>
	);
};

export default AnotherListItem;
