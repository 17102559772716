import React, { useContext, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { GatsbyImage } from 'gatsby-plugin-image';
import {
	getDiscountPrice,
	getMetafield,
	getMetafieldParse,
	getPrice,
} from '../../../../utils/helpers';
import { StoreContext } from '../../../../context/store-context';
import applyDiscount from '../../../../utils/cart/applyDiscount';

import 'react-lazy-load-image-component/src/effects/blur.css';
import './ProductBundlePiece.scss';
import getProductsByHandle from '../../../../api/getProductsByHandle';
import isAWholeNumber from '../../../../helpers/isAWholeNumber';

const ProductBundlePiece = ({ product, setSelectedProduct }) => {
	const [bundles, setBundles] = useState([]);
	const [isPieceSelected, setIsPieceSelected] = useState(0);
	const { allProducts, cart } = useContext(StoreContext);

	const unitTwo = getMetafield(product, 'bundle_x2');
	const unitThree = getMetafield(product, 'bundle_x3');

	const productBundleX2 = allProducts?.find((product) =>
		product.handle.includes(unitTwo)
	);
	const productBundleX3 = allProducts?.find((product) =>
		product.handle.includes(unitThree)
	);

	useEffect(() => {
		setBundles([product, productBundleX2, productBundleX3]);
	}, []);

	const handleSelect = (bundle, index) => {
		const bundleProduct = {
			product: bundle,
			oldPrice: bundle.variants[0].compareAtPrice ?? null,
			price: isAWholeNumber(bundle.variants[0].price)
				? bundle.variants[0].price
				: getPrice(bundle.variants[0].price, cart, 2),
			units: index + 1,
		};
		setSelectedProduct(bundleProduct);
		setIsPieceSelected(index);
	};

	return (
		<div className='pdp-bundle-brief'>
			<h3>Bundle & Save</h3>
			<div className='bundle-options'>
				{bundles?.map((bundle, index) => (
					<div
						className={`bundle-item ${index > 0 ? 'save' : ''}`}
						key={`${product.title}-${index + 1}`}
						onClick={() => handleSelect(bundle, index)}
					>
						<div
							className={`product-image ${
								isPieceSelected === index ? 'selected' : ''
							}`}
						>
							<GatsbyImage
								alt={`${product.title} unit ${index + 1}`}
								effect
								image={
									bundle.media[0].preview.image.localFile
										.childImageSharp.gatsbyImageData
								}
							/>
						</div>
						<p className='buy-text'>Buy {index + 1}:</p>

						<div className='price'>
							{index > 0 && (
								<span className='old-price'>
									£{bundle.variants[0].compareAtPrice}
								</span>
							)}
							<h4>
								{isAWholeNumber(bundle.variants[0].price) ? (
									<>
										{index === 0 && '£'}
										{bundle.variants[0].price}
									</>
								) : (
									getPrice(bundle.variants[0].price, cart, 2)
								)}
							</h4>
						</div>

						{index === 1 && <p className='discount'>Save 5%</p>}
						{index === 2 && <p className='discount'>Save 10%</p>}
					</div>
				))}
			</div>
		</div>
	);
};

export default ProductBundlePiece;
