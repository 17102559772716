// External
import React, { useEffect, useRef, useState, Suspense, lazy } from 'react';

// Internal
import YotpoWidget from '../../components/Yotpo/YotpoWidget';
import {
	getMetafieldsObject,
	isBrowser,
	getMetafield,
	sendViewItemEvent,
} from '../../utils/helpers';
import TextSlider from '../../components/TextSlider';
import ProductFirstScreenV2 from './ProductFirstScreenV2';
import './ProductPage.scss';
import Loader from '../../components/Loader';

const TiredOfHair = lazy(() => import('./TiredOfHair/TiredOfHair'));
const ProductAwardsContainer = lazy(() =>
	import('./ProductsAwardsV2/ProductAwardsContainer')
);
const ProductSteps = lazy(() => import('./ProductSteps/ProductSteps'));
const MiracleInfo = lazy(() => import('./MiracleInfo'));
const ProductTalksV2 = lazy(() => import('./ProductTalksV2'));
const ProductDifference = lazy(() => import('./ProductDifference'));
const ProductFaq = lazy(() => import('./ProductFaq'));
const TrustPilot = lazy(() => import('../../components/TrustPilot'));
const ProductHairBenefits = lazy(() => import('./ProductHairBenefits'));
const ProductIncludeWith = lazy(() => import('./ProductIncludeWith'));
const ProductTestimonial = lazy(() => import('./ProductTestimonial'));
const ProductDetail = lazy(() => import('./ProductDetail'));
const ProductTestimonials = lazy(() => import('./ProductTestimonials'));
const ProductCollections = lazy(() => import('./ProductCollections'));
const ProductCustomers = lazy(() => import('./ProductCustomers'));
const AsSeenInV2 = lazy(() => import('../../components/AsSeenIn'));

const ProductPageV2 = ({
	product = {},
	collections,
	cart,
	allBaseColours,
	productsList,
	shadeShotsProducts,
	isPermanentColour,
	rating,
	sellingPlanGroup,
	otherCollections,
}) => {
	const frequentlyMainProductHandle = getMetafield(
		product,
		'frequently_main_product'
	);
	const frequentlyProductsHandles = getMetafield(
		product,
		'frequently_products'
	);

	const alternativeLooks = getMetafield(product, 'alternative_looks');
	const hideJoshTalks = getMetafield(product, 'hide_josh_talks_section');
	const hideAccolades = getMetafield(product, 'hid_accolades_sections');
	const hideClientSay = getMetafield(product, 'hide_clients_say_section');
	const hideHowToUse = getMetafield(product, 'hide_how_to_use_section');
	const hideCollectionsPair = getMetafield(
		product,
		'hide_collections_pair_section'
	);
	const hideBenefitsSection = getMetafield(
		product,
		'hide_hair_benefits_section'
	);

	const isVariant = JSON.parse(getMetafield(product, 'is_variant'));
	const isRootSmudge = JSON.parse(getMetafield(product, 'is_root_smudge'));

	const hidePairWith = getMetafield(product, 'hide_pairs_with_section');
	const productHasPerfectBundle = getMetafield(product, 'perfect_bundle');
	const [activeVariant, setActiveVariant] = useState(null);
	const isMiracle = JSON.parse(getMetafield(product, 'is_miracle_system'));

	const yotpoRef = useRef(null);

	const productMetafield = getMetafieldsObject(product?.metafields);

	const scrollToReviews = () =>
		window.scrollTo({
			top: yotpoRef.current.getBoundingClientRect().top - 40,
			behavior: 'smooth',
		});

	useEffect(() => {
		isBrowser && sendViewItemEvent(product);
	}, []);

	useEffect(() => {
		const item = {
			ProductName: product.title,
			ProductID: product.id,
			Brand: 'Josh Wood Colour',
			Price: `£${product.variants[0].price}`,
			imageURL: product?.media[0].preview.image.originalSrc,
			URL: window.location.href,
		};
		window.klaviyo?.push(['track', 'Viewed Product', item]);
	}, [product]);

	return (
		<div className='pdp-2'>
			<TextSlider />
			<ProductFirstScreenV2
				{...{
					product,
					collections,
					cart,
					allBaseColours,
					productsList,
					shadeShotsProducts,
					isPermanentColour,
					activeVariant,
					setActiveVariant,
					rating,
					scrollToReviews,
					sellingPlanGroup,
				}}
			/>

			<Suspense fallback={<Loader />}>
				<AsSeenInV2 />
				<TrustPilot id='5419b6ffb0d04a076446a9af' height='25px' testId="trustpilot-normal" />

				{isMiracle && (
					<>
						<ProductTestimonial product={product} />

						{!hideBenefitsSection && (
							<ProductHairBenefits product={product} />
						)}

						<ProductSteps product={product} />

						<TiredOfHair />

						<MiracleInfo />
					</>
				)}

				{!hideAccolades && <ProductAwardsContainer product={product} />}

				{isMiracle && <ProductTestimonials />}

				{!hideJoshTalks && (
					<ProductTalksV2
						product={product}
						activeVariant={activeVariant}
					/>
				)}

				<ProductDifference />

				{isMiracle && <ProductIncludeWith product={product} />}

				{!hideClientSay && (
					<ProductTalksV2
						product={product}
						activeVariant={activeVariant}
						section='Clients Say'
					/>
				)}

				<ProductCustomers />

				<YotpoWidget yotpoRef={yotpoRef} product={product} />

				<TextSlider />

				<ProductFaq product={product} />

				{isMiracle && (
					<ProductDetail
						{...{
							product,
							collections,
							cart,
							allBaseColours,
							productsList,
							shadeShotsProducts,
							isPermanentColour,
							activeVariant,
							setActiveVariant,
							rating,
							scrollToReviews,
							sellingPlanGroup,
						}}
					/>
				)}

				{!hideCollectionsPair && (
					<ProductCollections
						{...{
							product,
							otherCollections,
							sellingPlanGroup,
							cart,
						}}
					/>
				)}
			</Suspense>
		</div>
	);
};

export default ProductPageV2;
