import React, { useContext, useEffect, useState } from 'react';

import { useWindowWidth } from '../../../utils/hooks';
import { StoreContext } from '../../../context/store-context';
import ProductInfoCollection from '../../products/ProductFirstScreen/ProductInfo/ProductInfoCollection';
import ProductInfoContainerV2 from './ProductInfoV2';
import ProductImages from './ProductImages';

import './ProductFirstScreenV2.scss';
import ProductInfoTitleV2 from './ProductInfoV2/ProductInfoTitleV2';
import TrustPilot from '../../../components/TrustPilot';

const ProductFirstScreenV2 = ({
	product: currentItem,
	cart,
	collections,
	addToCart,
	customPreviewImage,
	allBaseColours,
	productsList,
	shadeShotsProducts,
	isPermanentColour,
	activeVariant,
	setActiveVariant,
	rating,
	scrollToReviews,
	sellingPlanGroup,
}) => {
	const { currentPermanent } = useContext(StoreContext);
	const windowWidth = useWindowWidth();
	const [withShadeShot, setWithShadeShot] = useState([]);
	const [shots, setShots] = useState([]);
	const [product, setProduct] = useState(currentPermanent || currentItem);
	const [miracleSystem, setMiracleSystem] = useState({});

	useEffect(() => {
		if (currentPermanent) {
			setProduct(currentPermanent);
		}
	}, [currentPermanent]);

	useEffect(() => {
		let shadeShots = [];
		const { title } = product,
			colourNumber = title.split('')[2],
			necessaryProductPlusShadeShot =
				productsList &&
				productsList
					.filter(
						(item) =>
							item.title.includes('Shot') &&
							item.handle.includes('permanent')
					)
					.filter(
						(item) =>
							typeof +item.handle.split('-')[2] === 'number' &&
							item.handle.split('-')[2]
					)
					.filter((item) => item.title.includes(colourNumber));

		if (
			isPermanentColour &&
			necessaryProductPlusShadeShot &&
			necessaryProductPlusShadeShot.length
		) {
			shadeShots = necessaryProductPlusShadeShot[0].variants
				.filter((item) => !item.title.includes('Shot'))
				.map(({ title }) => {
					const variantId = shadeShotsProducts.find((item) =>
						item.title.includes(title)
					);
					return { title, variantId };
				});
			setShots(shadeShots);
		}
		setWithShadeShot(necessaryProductPlusShadeShot);
		const miracleSystem =
			productsList &&
			productsList.find((item) => item.handle === 'miracle-system');
		setMiracleSystem(miracleSystem);
	}, []);

	return (
		<div className='pdp-v2-main' id='select-shade'>
			<div className='container'>
				<div className='pdp-v2-main__content'>
					<ProductInfoTitleV2
						product={product}
						productsList={productsList}
						allBaseColours={allBaseColours}
						withShadeShot={withShadeShot}
						shots={shots}
						shadeShotsProducts={shadeShotsProducts}
						sellingPlanGroup={sellingPlanGroup}
						miracleSystem={miracleSystem}
						id='top-title'
					/>
					<TrustPilot id='5419b6ffb0d04a076446a9af' height='25px' testId="trustpilot-top" />
					<div className='pdp-v2-main__content__first'>
						<ProductImages
							{...{
								allBaseColours,
								shadeShotsProducts,
								withShadeShot,
								miracleSystem,
								product,
								customPreviewImage,
								productsList,
								shots,
							}}
						/>
					</div>

					<div className='pdp-v2-main__content__second'>
						{!isPermanentColour ? (
							<ProductInfoContainerV2
								{...{
									product,
									collections,
									cart,
									addToCart,
									windowWidth,
									allBaseColours,
									productsList,
									shadeShotsProducts,
									withShadeShot,
									shots,
									activeVariant,
									setActiveVariant,
									rating,
									scrollToReviews,
									sellingPlanGroup,
									miracleSystem,
								}}
							/>
						) : (
							<ProductInfoCollection
								{...{
									product,
									collections,
									cart,
									addToCart,
									windowWidth,
									productsList,
									allBaseColours,
									shadeShotsProducts,
									shots,
									activeVariant,
									setActiveVariant,
									rating,
									sellingPlanGroup,
								}}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductFirstScreenV2;
